import React from "react";
import { LoginOrRegister } from "components/LoginOrRegister/LoginOrRegister";
import { useTranslation } from "react-i18next";
import { SEO } from "components/Seo/Seo";

export const Login = (): React.ReactElement => {
  const [translate] = useTranslation();

  return (
    <>
      <SEO title={translate("hwa.screens.login.title")} />
      <LoginOrRegister defaultTab="Login" />
    </>
  );
};
