import { Login } from "screens/Login";
import { withServerSideCatalogue } from "lib/hoc/withServerSideCatalogue";
import { withServerSideProps } from "lib/serverSideProps/withServerSideProps";
import { withCatalogueServerSideProps } from "lib/serverSideProps/withCatalogue";
import { withUserServerSideProps } from "lib/serverSideProps/withUser";
import { withWebLayout } from "lib/hoc/withWebLayout";
import { withHOC } from "lib/hoc/withHOC";

export const getServerSideProps = withServerSideProps([
  withCatalogueServerSideProps,
  withUserServerSideProps,
]);

export default withHOC([withServerSideCatalogue, withWebLayout], Login);
